<template>
	<app-layout size="none">
		<template slot="header">
			<el-tabs class="infoTab" @tab-click="changeQueryType" v-model="activeName">
				<el-tab-pane v-for="(tab, i) in tabOptions" :label="`${tab.label}(${queryTypeCount[tab.name] || 0})`" :key="i" :name="tab.name">
				</el-tab-pane>
			</el-tabs>
			<el-row style="padding: 0 16px; text-align: right; margin: 20px 0 10px" v-if="activeName === '2'">
				<el-button size="small" @click="batchJudge" :disabled="selection.length === 0" type="primary">一键复评</el-button>
			</el-row>
		</template>
		<div :style="activeName != '2' ? 'margin-top:20px' : ''" style="padding: 0 16px" v-loading="pageLoading" class="tableBackground">
			<el-table :data="tableData" v-if="!pageLoading" @selection-change="selectChange" :row-key="getRowKey">
				<el-table-column type="selection" v-if="activeName == '2'" :reserve-selection="true" width="60" align="center"></el-table-column>
				<el-table-column label="序号" align="center" width="80">
					<template v-slot="{ $index }">{{ $index + 1 }}</template>
				</el-table-column>
				<el-table-column label="计划名称" align="center" prop="scheduleName"></el-table-column>
				<el-table-column label="公司名称" align="center" prop="companyName"></el-table-column>
				<el-table-column label="姓名" align="center" prop="name"></el-table-column>
				<el-table-column label="工号" align="center" prop="jobNumber"></el-table-column>
				<el-table-column label="岗位" align="center" prop="jobName"></el-table-column>
				<el-table-column label="现职级代码" align="center" prop="nowLevelAndCodeName"></el-table-column>
				<el-table-column label="现职等" align="center" prop="nowJobEtc"></el-table-column>
				<el-table-column
					label="评价职级代码"
					align="center"
					v-if="activeName === '4'"
					key="evaluationLevelAndCodeName"
					prop="evaluationLevelAndCodeName"
				></el-table-column>
				<el-table-column
					label="评价职等"
					align="center"
					v-if="activeName === '4'"
					key="evaluationJobEtc"
					prop="evaluationJobEtc"
				></el-table-column>
				<el-table-column label="状态" align="center" prop="statusName"></el-table-column>
				<el-table-column label="更新时间" align="center" prop="updateTime"></el-table-column>
				<el-table-column label="操作" align="center">
					<template v-slot="{ row }">
						<el-popover placement="left" width="96" v-if="commands.length > 3" trigger="click">
							<el-button type="text" slot="reference">
								<img src="../../assets/button/操作图标@3x.png" width="16px" />
							</el-button>
							<ul class="drop-menu">
								<li class="menu-item" v-for="(item, i) in commands" @click="operate(row, item)" :key="i">{{ item.label }}</li>
							</ul>
						</el-popover>
						<el-button type="text" v-for="(item, i) in commands" @click="operate(row, item)" :key="i">{{ item.label }}</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<el-pagination
			background
			slot="footer"
			:current-page="apiParams.pageNo"
			:page-size="apiParams.pageSize"
			@current-change="handleCurrentChange"
			layout="prev, pager, next"
			:page-count="totalPage"
		>
		</el-pagination>
	</app-layout>
</template>
<script>
import PageMixin from '../../mixins/pageQuery';
import { getAssessmentList, affirmAssessment, batchJudge } from '../../api/staffSystem';
export default {
	mixins: [PageMixin],
	data() {
		return {
			activeName: '0',
			tabOptions: [
				{
					label: '待自评',
					name: '0'
				},
				{
					label: '待复评',
					name: '2'
				},
				{
					label: '待确认',
					name: '4'
				}
			],
			pageLoading: false,
			commandsMap: {
				0: [
					{
						label: '开始自评',
						value: 1,
						method: 'judge/self'
					}
				],
				2: [
					{
						label: '开始复评',
						value: 2,
						method: 'judge/again'
					}
				],
				4: [
					{
						label: '通过',
						value: 1,
						method: 'affirm'
					},
					{
						label: '不通过',
						value: 0,
						method: 'affirm'
					},
					{
						label: '详情',
						value: 2,
						method: 'viewDetails'
					}
				]
			},
			queryTypeCount: [],
			loading: false,
			tableData: [],
			selection: [],
			apiParams: {
				pageNo: 1,
				pageSize: 50,
				type: '0'
			}
		};
	},
	computed: {
		commands() {
			return this.commandsMap[this.activeName];
		}
	},
	mounted() {
		this.queryToApiParams();
		this.getQueryTypeCount();
		this.activeName = this.apiParams.type;
		this.getPageData();
	},
	methods: {
		selectChange(selection) {
			this.selection = selection;
		},
		getRowKey(row) {
			return row.id;
		},
		batchJudge() {
			this.$confirm('<h4>是否使用上一个评价人的评价结果作为这次复评结果？</h4>', {
				dangerouslyUseHTMLString: true,
				type: 'warning'
			}).then(() => {
				this.pageLoading = true;
				batchJudge({ assessorResultIds: this.selection.map((v) => v.id) }).then((res) => {
					this.pageLoading = false;
					if (res._responseStatusCode === 0) {
						this.$message.success('复评成功!');
						this.getPageData();
						this.getQueryTypeCount();
					}
				});
			});
		},
		operate(row, command) {
			if (command.method === 'affirm') {
				this.$confirm(`你确定要${command.value === 1 ? '通过' : '不通过'}此评价?`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					affirmAssessment({
						staffResultId: row.staffResultId,
						status: command.value
					}).then((res) => {
						if (res.code === 0) {
							this.getPageData();
							this.getQueryTypeCount();
							this.$message.success('操作成功!');
						}
					});
				});
			} else if (command.method === 'judge/self') {
				this.$router.push({
					path: '/staff/judge/self',
					query: {
						id: row.id,
						employeeName: row.name
					}
				});
			} else if (command.method === 'judge/again') {
				this.$router.push({
					path: '/staff/judge/again',
					query: {
						id: row.id,
						employeeName: row.name
					}
				});
			} else if (command.method === 'viewDetails') {
				this.$router.push({
					path: '/result_info',
					query: {
						id: row.staffResultId,
						type: 'match_position'
					}
				});
			}
		},
		getQueryTypeCount() {
			Promise.all([
				this.queryAssessmentList({ pageNo: 1, pageSize: 1, type: 0 }),
				this.queryAssessmentList({ pageNo: 1, pageSize: 1, type: 2 }),
				this.queryAssessmentList({ pageNo: 1, pageSize: 1, type: 4 })
			]).then((resList) => {
				this.queryTypeCount = {
					0: resList[0] && resList[0].totalNum,
					2: resList[1] && resList[1].totalNum,
					4: resList[2] && resList[2].totalNum
				};
			});
		},
		async queryAssessmentList(params) {
			return getAssessmentList(params).then((res) => {
				if (res._responseStatusCode === 0) {
					return res;
				}
				return null;
			});
		},
		async getAssessmentList() {
			return getAssessmentList(this.apiParams).then((res) => {
				this.tableData = res.list;
				this.totalPage = res.totalPage;
			});
		},
		async getPageData() {
			this.apiParamsToQuery();
			this.pageLoading = true;
			try {
				await this.getAssessmentList();
			} finally {
				this.pageLoading = false;
			}
		},
		changeQueryType(tab) {
			this.apiParams.type = tab.name;
			this.getPageData();
		}
	}
};
</script>
